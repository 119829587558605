console.log('Hii');
// Import polyfills
import './js/polyfills';
import './js/libraries';
import './js/scripts';
import './js/alpine';
import './js/vue';

// Import styles
import './sass/style.scss';
