import $ from 'jquery';

export default (container?: HTMLElement) => {
    // Akkordeon
    $('.wly-accordeon__title').click(function () {
        $(this).parent().toggleClass('active');
        $(this).parent().siblings('.wly-accordeon__item').removeClass('active');

        $('.wly-accordeon__item').each(function () {
            if ($(this).hasClass('active')) {
                $(this).children('.wly-accordeon__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).children('.wly-accordeon__content').slideUp();
            }
        });
    });

    // Menu-Akkordeon
    $('.menu-cart__title').click(function () {
        $(this).closest('.menu-cart').toggleClass('active');
        $(this).closest('.menu-cart').siblings('.menu-cart').removeClass('active');

        $('.menu-cart').each(function () {
            if ($(this).hasClass('active')) {
                $(this).find('.menu-cart__content').slideDown();
            } else {
                $(this).removeClass('active');
                $(this).find('.menu-cart__content').slideUp();
            }
        });
    });
};
